<template>
  <div>
    <div class="group-card-row23 mb-1 w-100">
      <div class="group-card group-card-mobile mt-1 w-100">
        <div class="card">
          <div class="card-body p-1">
            <b-row class="align-items-center">
              <b-col lg="4" sm="12">
                <div class="truncate group-truncate34 d-flex">
                  <div class="text-start">
                    <span class="title-name d-flex"
                      >{{ $t("sim.allocated") }} :
                      <span class="vl-text">{{ sim.allocated_to }}</span></span
                    >
                    <span class="title-name"
                      >{{ $t("sim.SIM_Number") }} :
                      <span class="vl-text">{{ sim.sim_number }}</span></span
                    >
                  </div>
                </div></b-col
              >
              <b-col lg="4" sm="12">
                <div class="d-flex truncate group-truncate34">
                  <div class="truncate group-truncate34 text-start">
                    <span class="title-name d-flex"
                      >{{ $t("sim.operator") }} :
                      <span class="vl-text">{{ sim.operator }}</span></span
                    >
                    <span class="title-name mb-0"
                      >{{ $t("sim.status") }} :
                      <span class="vl-text">{{ sim.status }}</span></span
                    >
                  </div>
                </div>
              </b-col>
              <b-col lg="4" sm="12">
                <div class="truncate group-truncate34 text-start">
                  <span class="title-name d-flex"
                    >{{ $t("sim.sim_activate") }} :<span class="vl-text">{{
                      DateTimeConvert(
                        sim.sim_active_date,
                        constants.DATE_FORMAT
                      )
                    }}</span>
                  </span>
                  <div class="d-flex">
                    <span class="title-name">{{ $t("sim.labels") }} : </span>
                    <span style="margin-left: 13px; margin-top: 4px">
                      <DynamicBadge
                        :isList="true"
                        :entityType="'SIM'"
                        :tagsData="sim.labels"
                        :showTextInBadge="Number(7)"
                        :event="sim"
                        :maxTagShow="Number(4)"
                        :listApi="getAllSims"
                      ></DynamicBadge
                    ></span>
                  </div></div
              ></b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div class="alerts-search-comp">
      <b-card class="alert-search position-relative main-card-padding">
        <div class="m-2-br-alert-bottm">
          <div class="adduser-btn-row">
            <h4 class="card-title">
              {{ $t("Sim.RechargeHistory") }}
            </h4>
            <div class="filter-btn-outer-new d-flex">
              <b-button
                v-b-tooltip.hover.v-primary
                :title="$t('sim.AddHistory')"
                variant="outline-primary"
                class="btn-icon desktop-hidden mr-1"
                @click="addHistory()"
              >
                <feather-icon icon="PlusIcon" size="12" />
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                class="desktop-hidden"
                @click="showFiltermenu = !showFiltermenu"
              >
                <feather-icon icon="FilterIcon" size="15" />
              </b-button>
            </div>
            <div class="d-flex flex-wrap filter-card" v-if="showFiltermenu">
              <div class="alert-per-page" v-if="!isGroupEntity">
                <div class="addXIcon">
                  <feather-icon
                    class="sizeIcon"
                    @click="showFiltermenu = !showFiltermenu"
                    icon="XIcon"
                    size="15"
                  />
                </div>
                <label class="search-label mt-1">{{
                  $t("Notification.Entries")
                }}</label>
                <v-select
                  :clearable="false"
                  id="vue-select"
                  class="per-page-selector d-inline-block fix-83"
                  v-model="perPage"
                  :options="option"
                />
              </div>
              <search
                v-if="!isGroupEntity"
                style="float: left"
                class="search-comp no-btm-0"
                v-model="searchFiled"
                :searchColumn="searchColumn"
                :operator="operator"
              ></search>
              <b-button
                v-b-tooltip.hover.v-primary
                variant="primary"
                class="mobile-hidden"
                @click="addHistory()"
              >
                <span class="mobile-hidden">{{ $t("sim.recharge") }}</span>
              </b-button>
              <b-button
                variant="outline-primary"
                size="sm"
                class="add-user-btn v2-back ml-10"
                @click="redirectToBack()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">{{ $t("sim.Back") }}</span>
              </b-button>
            </div>
          </div>
        </div>

        <RechargeHistory
          ref="RechargeHistory"
          :searchFiled="searchFiled"
          :perPage="perPage"
          :groupName="groupName"
        />
        <AddRecharge
          ref="AddRecharge"
          :refreshed="refreshed"
          :selectedRow="selectedRow"
          :rechargeModelName="modelName"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BAlert,
  BButton,
  VBTooltip,
  BAvatar,
  BTooltip,
  BLink,
  BCol,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BBadge
} from "bootstrap-vue";
import SimCreate from "./SimCreate.vue";
import RechargeHistory from "@/views/Sim/RechargeHistory.vue";
import constants from "@/utils/constants";
import AddRecharge from "./AddRecharge.vue";
import vSelect from "vue-select";
import Search from "../Common/search.vue";
import AllIcon from "@/assets/images/icons/SIM-icon/all.svg";
import ImportCSV from "@/assets/images/icons/SIM-icon/importCSV.svg";
import ActiveIcon from "@/assets/images/icons/SIM-icon/active.svg";
import InActiveIcon from "@/assets/images/icons/SIM-icon/inactive.svg";
import AvailableIcon from "@/assets/images/icons/SIM-icon/available.svg";
import OverdueIcon from "@/assets/images/icons/SIM-icon/overdue.svg";
import DatePicker from "./DatePicker.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import Badge from "@/layouts/components/Badge.vue";
import DeviceService from "@/libs/api/device-service";

export default {
  components: {
    BCard,
    BAlert,
    BButton,
    VBTooltip,
    Search,
    BFormInput,
    vSelect,
    RechargeHistory,
    BAvatar,
    BTooltip,
    BLink,
    BCol,
    BRow,
    OverdueIcon,
    AllIcon,
    ActiveIcon,
    InActiveIcon,
    AvailableIcon,
    DatePicker,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    SimCreate,
    AddRecharge,
    ImportCSV,
    DynamicBadge,
    Badge,
    BBadge
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      fileName: "",
      selectedRow: {},
      searchColumn: ["Amount"],
      searchFiled: {},
      operator: "ilike",
      perPage: 10,
      option: ["10", "20", "30"],
      showFiltermenu: false,
      sim_number: 0,
      modelName: "add_recharge_modal",
      test: [
        {
          key: "all",
          label: "All",
          icon: AllIcon,
          count: "54"
        },
        {
          key: "active",
          label: "Active",
          icon: ActiveIcon,
          count: "54"
        },
        {
          key: "inactive",
          label: "InActive",
          icon: InActiveIcon,
          count: "54"
        },
        {
          key: "available",
          label: "Available",
          icon: AvailableIcon,
          count: "54"
        },
        {
          key: "overdue",
          label: "Overdue",
          icon: OverdueIcon,
          count: "54"
        }
      ],
      sim: {
        sim_number: "",
        msisdn: "",
        operator: "",
        sim_active_date: null,
        action: "create",
        labels: [],
        status: null
      }
    };
  },
  props: ["isGroupEntity", "groupName"],
  watch: {},
  mounted() {
    const { perPage } = this.$route.query;
    const id = this.$route.params.id;
    this.getSimDetails(id);
    if (perPage) {
      this.perPage = parseInt(perPage);
    }
    this.onWindowResize();
    // window.addEventListener("resize", this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    async refreshed() {
      this.selectedRow = {};
      this.$bvModal.hide(this.modelName);
      if (this.$refs.RechargeHistory) {
        this.$refs.RechargeHistory.debounceGetAllRecharge();
      }
    },
    async getSimDetails() {
      // api call
      try {
        const id = this.$route.params.id;

        let requestData = {
          sim_id: id
        };
        let response = await new DeviceService().getSimDetails(requestData);
        console.log("response", response);
        if (response && response.data) {
          this.sim = response.data;
        }
      } catch (er) {
        console.log(er);
      }
    },
    openFileDialog() {
      this.$refs.csvUploadModal.$bvModal.show("csv-upload-modal");
    },
    addHistory() {
      this.selectedRow = {};
      this.$bvModal.show(this.modelName);
    },
    onWindowResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 820) {
        this.showFiltermenu = true; //Mobile
      } else {
        this.showFiltermenu = false; //Desktop
      }
    },
    DateTimeConvert(date, format) {
      return this.formattedDateTime(date, format);
    },
    getAllSims() {},
    redirectToBack() {
      if (this.$route.query.page == 1) {
        this.$router.go(-2);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.text-start {
  text-align: start;
}
.truncate .group-truncate3434 {
  align-items: center;
}
.vl-text {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}
.alert-search {
  .alert-per-page {
    margin-left: auto;
    margin-right: 15px;
  }

  .search-filter {
    margin-left: 0;
  }

  .operator-table-width {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .search-filter {
    margin-right: 15px;
  }
}

.sim-info-card {
  .b-avatar {
    .b-avatar-img {
      padding: 4px;

      img {
        object-fit: contain;
      }
    }
  }

  .cust-card-sim {
    display: inline-flex;
    padding: 15px;
    margin-right: 10px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;

    .sim-name-ev-block {
      padding-left: 15px;
      width: 100%;

      .media.event-Tag {
        padding-top: 5px;
      }

      .sim-name-ev-text {
        font-size: 16px;
        font-weight: 500;
        color: var(--sim-bg-dark);
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 87%;
        white-space: nowrap;
      }

      .sim-light-ev-text {
        font-size: 14px;
        min-width: 70%;
        line-height: 25px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        max-width: 255px;
        white-space: nowrap;
      }
    }

    .card-data-ev {
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      justify-content: space-between;
    }
  }
}

.dark-layout {
  .cust-card-sim {
    .sim-name-ev-block {
      .sim-name-ev-text {
        color: var(--light);
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .dash-width {
    width: 100%;
  }
}

.group-avtar {
  display: flex;
  justify-content: start;
  width: 30%;
}

.group-truncate3434 {
  align-items: center;
}

.group-name {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 25px;
  }
}

.group-card {
  box-shadow: 0px 4px 12px rgba(34, 41, 47, 0.1);
  border-radius: 8px;
  margin-right: auto;
  width: 19%;

  .card {
    margin-bottom: 0px;
    border: 1px solid transparent;
  }
}

.group-card-column .card-body {
  flex-direction: row-reverse !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height, or 100% */

  text-align: center;

  color: #6e6b7b;
}

@media only screen and (max-width: 820px) {
  .title-name {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem !important;
  }
  .group-card-row23 {
    .group-card-mobile {
      width: calc(50% - 5px);
      margin-top: 5px !important;
      margin-right: 2.5px !important;
      margin-left: 2.5px !important;
    }

    .icon-mobile-view {
      width: 48px !important;
      height: 48px !important;

      .group-svg {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        /* Safari & Chrome */
        -moz-transform: scale(0.7);
        /* Firefox */
        -ms-transform: scale(0.7);
        /* Internet Explorer */
        -o-transform: scale(0.7);
      }
    }
  }
}

.group-card-row23 .group-card:last-child {
  margin-right: 0 !important;
  /* Adjust the value as needed */
}

@import "@core/scss/vue/libs/vue-select.scss";

.normal-alerts {
  .group-svg {
    path {
      fill: var(--secondary);
    }

    .red-svg {
      fill: var(--danger);
    }

    .device-svg {
      stroke: var(--secondary);

      path {
        fill: none;
        stroke: var(--secondary);
      }
    }
  }
}
.title-name {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}
.active-alerts {
  .group-svg {
    path {
      fill: var(--primary);
    }

    .red-svg {
      fill: var(--danger);
    }

    .device-svg {
      stroke: var(--primary);

      path {
        fill: none;
        stroke: var(--primary);
      }
    }

    .geo-fn path {
      stroke: var(--primary);

      path {
        fill: none;
        stroke: var(--primary);
      }
    }

    .geo-route-fn path {
      path {
        fill: none;
        stroke: var(--primary);
      }
    }
  }
}

.dark-layout {
  .add-card {
    border: 1px solid #404656;
  }
  .normal-alerts {
    .group-svg {
      path {
        fill: var(--light);
      }

      .red-svg {
        fill: var(--danger);
      }

      .device-svg {
        stroke: var(--light);

        path {
          fill: none;
          stroke: var(--light);
        }
      }
    }
  }

  .active-alerts {
    .group-svg {
      path {
        fill: var(--primary);
      }

      .red-svg {
        fill: var(--danger);
      }

      .device-svg {
        stroke: var(--primary);

        path {
          fill: none;
          stroke: var(--primary);
        }
      }
    }
  }
}
</style>
